import React from 'react'

const Loader = () => {
  return (
    <div id="preloader">
        <div id="status">
            <div className="overlay-bg">
                <div className="text-bg">
                    <video autoPlay muted playsInline>
                        <source src='/Astra LOGO Animation in Black.mp4' />
                    </video>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Loader;