import React, { useState } from 'react';
import { FaArrowUp } from "react-icons/fa";
import './ScrollTop.css'

const ScrollTopButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className='scroll-top-button'>
            <FaArrowUp onClick={scrollToTop}
                className='top-icon'
                style={{ display: visible ? 'inline' : 'none' }} />
        </div>
    );
}

export default ScrollTopButton; 
